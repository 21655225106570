<template>
  <el-dialog
    title="新增角色"
    :visible.sync="showDialog"
    width="500px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <el-form
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="角色名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入角色名称"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"
        />
      </el-form-item>
      <el-form-item label="角色描述：">
        <el-input
          type="textarea"
          v-model.trim="form.description"
          placeholder="请输入角色描述"
          clearable
          maxlength="100"
          show-word-limit
          class="inputWidth"
        />
      </el-form-item>
      <el-form-item label="角色状态：">
        <el-radio-group v-model="disabled">
          <el-radio label="0">有效</el-radio>
          <el-radio label="1">失效</el-radio>
        </el-radio-group>
      </el-form-item></el-form
    ><span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span></el-dialog
  >
</template>

<script>
export default {
  name: 'add_role',
  props: {
    showDialog: Boolean,
  },
  data() {
    return {
      form: { disabled: null, name: '', description: '' },
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      },
      disabled: '0',
    }
  },
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.form.name = ''
        this.form.description = ''
        this.form.disabled = null
      })
    },
    closeFn() {
      this.$emit('closeDialog')
    },
    sureFn() {
      this.form.disabled = Number(this.disabled)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('sureDialog', this.form)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
</style>
